import {Accordion, Card} from "react-bootstrap"
import AboutTheCompanyTemplate from "./Templates/AboutTheCompanyTemplate";

const SebEmbedded = () => {

    const Heading = () => <h4 className={'fst-italic'}>SEB Embedded</h4>

    const AboutTheCompany = () =>
        <AboutTheCompanyTemplate>
            SEB Embedded is part of Sweden's biggest banking company, SEB which has
            over 150 years of experience in the banking industry.
            The main focus is on providing banking as a service
            to companies who would like to "embed" finance in their products.
        </AboutTheCompanyTemplate>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>QA / Test Automation Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2023 to 2024</p>
        </div>

    const ContributionHeading = () => <h5>Key responsibilities</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Contribute to quality and work with Test Automation
            </li>
            <li>
                Create new tools and maintain existing tools that support quality and enable faster releases
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "6"

    return (
        <Card className={'border-top border-left border-right border-success'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default SebEmbedded
