import {Container} from "react-bootstrap";

const Spotify = () =>
    <Container>
        <hr />
        <h2 className={'text-center py-2'}>
            Fun
        </h2>
        <p className={'text-center pb-3'}>
            Music
        </p>
        <iframe style={{"borderRadius": "12px"}}
                src="https://open.spotify.com/embed/playlist/1MvYZtmaIXhk8SDK5mRVnJ?utm_source=generator&theme=0"
                width="100%"
                height="352"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"></iframe>
    </Container>

export default Spotify;
