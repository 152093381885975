import {Accordion, Card} from "react-bootstrap"
import AboutTheCompanyTemplate from "./Templates/AboutTheCompanyTemplate";

const Axiomatics = () => {

    const Heading = () => <h4 className={'fst-italic'}>Axiomatics</h4>

    const AboutTheCompany = () =>
        <AboutTheCompanyTemplate>
            Information security company that develops and sells software based on Attribute based access control
            using XACML OASIS specification.
        </AboutTheCompanyTemplate>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>Test Automation Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2017 to 2020</p>
        </div>

    const ContributionHeading = () => <h5>Key contributions</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Create test strategy and recommend tests to be written at the 'right' level.
            </li>
            <li>
                Quality coach, training fellow colleagues to think about testing approaches, test expertise.
            </li>
            <li>
                Create automated tests using Selenium, Java, Docker, Redhat virtualization, API tests.
            </li>
            <li>
                Worked with infrastructure and software build development and maintenance like setting up databases,
                Gradle build systems.
            </li>
            <li>
                Tools used: Selenium with Java, Bitbucket, Gradle, Maven, Junit, IntelliJ, Postman, SQL, JIRA,
                JavaScript, Artifactory, Docker, AWS, Wrk, Locust, Kibana
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "3"

    return (
        <Card className={'border-top border-left border-right border-success'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default Axiomatics
