import {Accordion, Card} from "react-bootstrap";
import AboutTheCompanyTemplate from "./Templates/AboutTheCompanyTemplate";

const SoftwareTrainee = () => {

    const Heading = () => <h4 className={'fst-italic'}>Infosys</h4>

    const AboutTheCompany = () =>
        <AboutTheCompanyTemplate>
            Started my career as a software engineer trainee with Infosys, a company based in
            India providing consultancy services in many countries.
        </AboutTheCompanyTemplate>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>System engineer trainee</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2011 to 2012</p>
        </div>

    const ContributionHeading = () => <h5>Trainings</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Web programming with ASP .NET
            </li>
            <li>
                Agile methodologies
            </li>
            <li>
                ASP .NET framework, Visual Studio, Visual Studio, SQL, Java
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "0"

    return (
        <Card className={'border-top border-left border-right border-info'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default SoftwareTrainee
