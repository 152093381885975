import {Accordion, Col, Container, Row} from "react-bootstrap"
import Nordstrom from "./Companies/Nordstrom"
import SoftwareTrainee from "./Companies/SoftwareTrainee"
import Ica from "./Companies/Ica";
import Axiomatics from "./Companies/Axiomatics";
import Cepheid from "./Companies/Cepheid";
import Mitigram from "./Companies/Mitigram";
import Seb from "./Companies/Seb";
import SebEmbedded from "./Companies/SebEmbedded";

const WorkHistory = () => {

    const WorkHistoryHeading = () =>
        <h2 className={'text-center'}>
            Work history
        </h2>

    const WorkHistorySectionDescription = () =>
        <p className={'text-center'}>
            List of companies I have worked for, contributions, responsibilities
        </p>

    const WorkHistoryAccordion = () =>
        <Container>
            <Row>
                <Col>
                    <Accordion defaultActiveKey={'7'} className={'py-3'}>
                        <Seb/>
                        <SebEmbedded/>
                        <Mitigram/>
                        <Cepheid/>
                        <Axiomatics/>
                        <Ica/>
                        <Nordstrom/>
                        <SoftwareTrainee/>
                    </Accordion>
                </Col>
            </Row>
        </Container>

    return (
        <Container id={'work-history-section'} className={'py-5'}>
            <WorkHistoryHeading/>
            <WorkHistorySectionDescription/>
            <WorkHistoryAccordion/>
        </Container>
    )
}

export default WorkHistory
