import {Accordion, Card} from "react-bootstrap"
import AboutTheCompanyTemplate from "./Templates/AboutTheCompanyTemplate";

const Seb = () => {

    const Heading = () => <h4 className={'fst-italic'}>SEB</h4>

    const AboutTheCompany = () =>
        <AboutTheCompanyTemplate>
            SEB is one of the biggest banks in Sweden and in Nordics having a 150 year history and bringing banking to everyone.
            Interesting fact: SEB is one of the first companies in the world to provide internet banking to its customers.
            I am currently working with the Mobile app teams where we cater to various segments of customers.
        </AboutTheCompanyTemplate>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>QA Engineer</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2024 to Current</p>
        </div>

    const ContributionHeading = () => <h5>Key responsibilities</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Contribute to testing, improve app quality.
            </li>
            <li>
                Work with Test Automation using Appium alongside Kotlin.
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "7"

    return (
        <Card className={'border-top border-left border-right border-primary-subtle'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default Seb
