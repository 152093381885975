import {Accordion, Card} from "react-bootstrap"
import AboutTheCompanyTemplate from "./Templates/AboutTheCompanyTemplate";

const Cepheid = () => {

    const Heading = () => <h4 className={'fst-italic'}>Cepheid</h4>

    const AboutTheCompany = () =>
        <AboutTheCompanyTemplate>
            Molecular diagnostics company that produces testing machines to detect diseases from patients. I am part of
            the software
            team called C360 that collects test data from the testing machines and aggregates this data in a graphically
            viewable dashboard.
        </AboutTheCompanyTemplate>

    const Role = () =>
        <div>
            <h5>Role</h5>
            <p>Senior Analyst, Devops QA</p>
        </div>

    const Duration = () =>
        <div>
            <h5>Duration</h5>
            <p>2020 to 2021</p>
        </div>

    const ContributionHeading = () => <h5>Key responsibilities</h5>
    const ContributionDescription = () =>
        <ul>
            <li>
                Work with Operations team to assure timely deployments, patch releases and infrastructure updates with
                high quality.
            </li>
            <li>
                Make sure that procedures and validated tools exist for various functions. Recommend industry best
                practises, new tools.
            </li>
            <li>
                Improve the continuous integration system and test automation strategy.
            </li>
        </ul>

    const Contribution = () =>
        <div>
            <ContributionHeading/>
            <ContributionDescription/>
        </div>

    const uniqueKey = "4"

    return (
        <Card className={'border-top border-left border-right border-primary'}>
            <Accordion.Item eventKey={uniqueKey}>
                <Accordion.Header as={Card.Header} eventKey={uniqueKey}>
                    <Heading/>
                </Accordion.Header>
                <Accordion.Collapse eventKey={uniqueKey}>
                    <Card.Body>
                        <AboutTheCompany/>
                        <Role/>
                        <Duration/>
                        <Contribution/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion.Item>
        </Card>
    )
}

export default Cepheid
